define("discourse/plugins/discourse-solved-reminders-plugin/discourse/templates/connectors/user-preferences-notifications/solutions-notification-preferences-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{solutions-notification-preferences user=this.model}}
  */
  {
    "id": "bPqGjvR3",
    "block": "[[[1,[28,[35,0],null,[[\"user\"],[[30,0,[\"model\"]]]]]]],[],false,[\"solutions-notification-preferences\"]]",
    "moduleName": "discourse/plugins/discourse-solved-reminders-plugin/discourse/templates/connectors/user-preferences-notifications/solutions-notification-preferences-container.hbs",
    "isStrictMode": false
  });
});