define("discourse/plugins/discourse-solved-reminders-plugin/discourse/initializers/extend-for-solved-reminders", ["exports", "discourse/lib/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-solved-reminders-plugin";
  var _default = _exports.default = (0, _api.apiInitializer)("0.11.1", api => {
    api.modifyClass("controller:preferences/notifications", {
      pluginId: PLUGIN_ID,
      actions: {
        save() {
          this.saveAttrNames.push("custom_fields");
          this._super(...arguments);
        }
      }
    });
  });
});